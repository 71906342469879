// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-creatorscode-js": () => import("./../src/pages/creatorscode.js" /* webpackChunkName: "component---src-pages-creatorscode-js" */),
  "component---src-pages-faq-and-support-js": () => import("./../src/pages/faq-and-support.js" /* webpackChunkName: "component---src-pages-faq-and-support-js" */),
  "component---src-pages-feed-js": () => import("./../src/pages/feed.js" /* webpackChunkName: "component---src-pages-feed-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-js": () => import("./../src/pages/learn.js" /* webpackChunkName: "component---src-pages-learn-js" */),
  "component---src-pages-search-js": () => import("./../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */)
}

